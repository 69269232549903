.video-bg{
    width: 30%;
}

.subtitle{
    text-shadow: gray 1px 0 1px;
}

@media screen and (min-width: 768px){
    .bg-cover {
        display: flex;
        flex-direction: column; 
        align-items: center; 
        justify-content: space-evenly; 
        background-color: lightgray !important
    }
}

@media screen and (max-width: 768px){
    .bg-cover {
        display: flex;
        flex-direction: column; 
        align-items: center; 
        justify-content: space-evenly; 
        background-color: lightgray;
    }
}

@media screen and (max-width: 540px){
    .bg-cover {
        background-color: inherit !important;
        position: absolute;
        right: 0;
    }
    .high-header{
        height: 7rem;
    }
    .video-section-content{
        height: 1000px;
        margin-top: 400px;
    }
    .video-section-content h3, .video-section-content h4{
        font-size: 25px;
    }

    .video-section-content.production h3, .video-section-content.production h4{
        line-height: 1rem;
    }
    
    .picto{
        display: none;
    }
    .img-container.video-section-content, .description.video-section-content{
        height: fit-content;
    }
    .back-buy-img-container.video-section-content{
        height: 300px;
    }

    .subtitle{
        width: 60%;
    }
}

@media screen and (max-width: 425px){
    .bg-cover {
        background-color: inherit !important;
        position: absolute;
        right: 0;
    }
    .high-header{
        height: 7rem;
    }
    .video-section-content{
        height: 1000px;
        margin-top: 400px;
    }
    .video-section-content h3, .video-section-content h4{
        font-size: 17px;
    }
    .subtitle{
        width: 80%;
    }
    
    .video-section-content ul li{
        margin-bottom: 5px;
    }
}
    