.post-date.subusing-title-long span:first-child{
    font-size: 1.5rem;
}

.post-date.subusing-title-medium span:first-child{
    font-size: 2rem;
}

.single-blog-box{
    text-align: center;
}