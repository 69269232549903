#sticky-nav {
    background-image: linear-gradient(135deg, #fede33 0 50%, #0c5ba8 50% 100%);
}

.main-menu nav li a{
    color: white;
    font-weight: 700;
}

@media screen and (max-width: 991px){
    ul.menu-items {
        background: #0c5ba8;
        color: #fede33;
    }
}
