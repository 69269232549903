.MuiTableCell-head {
    font-weight: 900 !important;
}

.line-list ul li {
    position: relative;
    padding-left: 20px;
    transition: all .3s;
}

.line-list ul li::before {
    position: absolute;
    font-size: 20px;
    content: "\f105";
    left: 0;
    top: 0;
    color: #fede33;
    font-family: "Font Awesome 5 Pro";
    transition: all .3s;
}
th {
    color: #0c5ba8 !important;
}
th.MuiTableCell-root.MuiTableCell-body {
    font-weight: 900 !important;
    color: #0c5ba8;
}
.MuiTableCell-footer{
    color: rgba(0, 0, 0, 0.54);
    font-size: 2rem;
    line-height: 3rem;
}
.remark-row{
    font-weight: 900 !important;
}

@media screen and (max-width: 425px){
    .bg-cover {
        background-color: inherit !important;
        position: absolute;
        right: 0;
    }

    .small-table{
        display: inline-block !important;
    }

    .big-table{
        display: none !important;
    }
}

@media screen and (max-width: 768px){
    .bg-cover {
        display: flex;
        flex-direction: column; 
        align-items: center; 
        justify-content: space-evenly; 
        background-color: lightgray;
    }
}

@media screen and (min-width: 768px){
    .small-table{
        display: none !important;
    }

    .big-table{
        display: inline-block !important;
    }
}