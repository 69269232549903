.single-panel {
    text-align: center;
    margin-bottom: 30px;
}

.single-panel h3 {
    margin-bottom: 20px;
}

.icon-shape {
    position: relative;
    transition: all .3s ease-in-out;
}

.panel-shape-icon {
    transition: all .3s ease-in-out;
    position: absolute;
    z-index: 11;
    color: #ffff;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
    margin-top: -16px;
}

.single-panel:hover span svg path {
    fill: rgb(255, 94, 16);
}

.single-panel:hover svg.panel-shape-icon path {
    fill: rgb(242, 243, 245);
}

.panel-icon {
    position: relative;
    margin-bottom: 20px;
}

.single-panel:hover .panel-shape-icon img {
    filter: brightness(200);
}

.our-panels img{
    height: 286px;
}

#benefits.video-section-content{
    height: fit-content;
}