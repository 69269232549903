.single-company {
    text-align: center;
    margin-bottom: 30px;
}

.single-company h3 {
    margin-bottom: 20px;
}

.icon-shape {
    position: relative;
    transition: all .3s ease-in-out;
}

.company-shape-icon {
    transition: all .3s ease-in-out;
    position: absolute;
    z-index: 11;
    color: #ffff;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
    margin-top: -16px;
}

.single-company:hover span svg path {
    fill: rgb(255, 94, 16);
}

.single-company:hover svg.company-shape-icon path {
    fill: rgb(242, 243, 245);
}

.company-icon {
    position: relative;
    margin-bottom: 20px;
}

.single-company:hover .company-shape-icon img {
    filter: brightness(200);
}

.our-companys img{
    height: 286px;
}

#benefits.video-section-content{
    height: fit-content;
}

.company-container{
    display: flex;
    align-items: center;
}