.footer-bootm{
    background-image: linear-gradient(135deg, #fede33 0 50%, #0c5ba8 50% 100%);
}

a.contact {
    color:#0c5ba8;
}

a.contact:hover {
    color:blueviolet;
}