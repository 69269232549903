.two-img-container{
    position: relative;
}

.main-img{
    left: 0px;
    top: 20px;
    position: absolute;
}

.badge-img{
    position: absolute;
    right: -10px;
    width: 200px;
}

@media screen and (max-width: 425px){
    .img-container.video-section-content, .description.video-section-content{
        height: fit-content;
    }
    .back-buy-img-container.video-section-content{
        height: 300px;
    }
    .picto{
        display: none;
    }
}

/* @media screen and (min-width: 768px){
    .picto{
        display: none;
    }
} 

@media screen and (min-width: 540px){
    .picto{
        display: none;
    }
}*/