.single-block {
    text-align: center;
    margin-bottom: 30px;
}

.single-block h3 {
    margin-bottom: 20px;
}

.icon-shape {
    position: relative;
    transition: all .3s ease-in-out;
}

.block-shape-icon {
    transition: all .3s ease-in-out;
    position: absolute;
    z-index: 11;
    color: #ffff;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
    margin-top: -16px;
}

.single-block:hover span svg path {
    fill: rgb(255, 94, 16);
}

.single-block:hover svg.block-shape-icon path {
    fill: rgb(242, 243, 245);
}

.block-icon {
    position: relative;
    margin-bottom: 20px;
}

.single-block:hover .block-shape-icon img {
    filter: brightness(200);
}

.our-blocks img{
    height: 300px;
}

.description.video-section-content{
    height: fit-content;
}

@media screen and (max-width: 425px){
    .img-container.video-section-content, .description.video-section-content{
        height: fit-content;
    }
    .back-buy-img-container.video-section-content{
        height: 300px;
    }
    .picto{
        display: none;
    }
}