/*
*
* ToTop
*/
.ui-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 100;
  width: 60px;
  height: 60px;
  font-size: 30px;
  line-height: 56px;
  color: #ffffff;
  overflow: hidden;
  background: gold;
  /* Pashneff #41A451*/
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  transition: .45s all ease-in-out;
  transform: translate3d(0, 100px, 0);
}

.ui-to-top:hover {
  color: #ffffff;
  background: #224769;
  /* Pashneff instead #000000*/
  text-decoration: none;
}

.ui-to-top:focus {
  color: #ffffff;
}

.ui-to-top.active {
  transform: translate3d(0, 0, 0);
}

html.mobile .ui-to-top,
html.tablet .ui-to-top {
  display: none !important;
}

#ui-to-top .MuiSvgIcon-root {
    padding-top: 5px;
    width: 2em;
    height: 2em;
}

@media (min-width: 576px) {
  .ui-to-top {
    right: 40px;
    bottom: 40px;
  }
}


@media screen and (max-width: 425px){
    .ui-to-top {
    right: 40px;
    bottom: 40px;
  }
}

@media screen and (min-width: 768px){
    .ui-to-top {
    right: 40px;
    bottom: 40px;
  }
}

@media screen and (max-width: 768px){
    .ui-to-top {
    right: 40px;
    bottom: 40px;
  }
}